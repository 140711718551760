<div class="background-image-container">
  <div class="container">
    <div class="jumbotron jumbotron-fluid">
      <div class="d-flex flex-wrap">
        <div>
          <h1 class="display-3">Dspace Unadeca</h1>
          <p class="lead">Bienvenido a el Repositorio de UNADECA que permite a la comunidad universitaria:</p>
        </div>
      </div>
      <ul>
        <li>Abrir este contenido a audiencias locales y globales, gracias a la interfaz.</li>
        <li>Gestionar y organizar colecciones de recursos digitales de manera eficiente.</li>
        <li>Realizar búsquedas avanzadas y filtros para encontrar rápidamente información específica.</li>
        <li>Permitir a los usuarios contribuir con contenido y colaborar en proyectos de investigación.</li>
      </ul>
      <p>La UNADECA es una institución académica comprometida con la excelencia en la educación superior y la investigación. El Repositorio de UNADECA es una herramienta vital en el apoyo a estas misiones, proporcionando acceso abierto a la producción intelectual de la universidad y fomentando la colaboración entre la comunidad académica.</p>
      <p>Visita nuestra web oficial <a href="https://unadeca.ac.cr" target="_blank">sitio web Unadeca</a>.</p>
    </div>
  </div>
  <picture class="background-image">
    <img alt="" [src]="'assets/dspace/images/banner.jpg'"/><!-- without the []="''" Firefox downloads both the fallback and the resolved image -->
  </picture>
</div>
